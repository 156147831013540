exports.components = {
  "component---src-pages-datenschutz-js": () => import("./../../../src/pages/datenschutz.js" /* webpackChunkName: "component---src-pages-datenschutz-js" */),
  "component---src-pages-downloads-js": () => import("./../../../src/pages/downloads.js" /* webpackChunkName: "component---src-pages-downloads-js" */),
  "component---src-pages-email-gesendet-js": () => import("./../../../src/pages/email-gesendet.js" /* webpackChunkName: "component---src-pages-email-gesendet-js" */),
  "component---src-pages-formulare-js": () => import("./../../../src/pages/formulare.js" /* webpackChunkName: "component---src-pages-formulare-js" */),
  "component---src-pages-impressum-js": () => import("./../../../src/pages/impressum.js" /* webpackChunkName: "component---src-pages-impressum-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-news-js": () => import("./../../../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-sportarten-js": () => import("./../../../src/pages/sportarten.js" /* webpackChunkName: "component---src-pages-sportarten-js" */),
  "component---src-pages-verein-js": () => import("./../../../src/pages/verein.js" /* webpackChunkName: "component---src-pages-verein-js" */),
  "component---src-templates-news-js": () => import("./../../../src/templates/news.js" /* webpackChunkName: "component---src-templates-news-js" */),
  "component---src-templates-sports-js": () => import("./../../../src/templates/sports.js" /* webpackChunkName: "component---src-templates-sports-js" */)
}

