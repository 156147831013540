import React, { Component } from "react"
import { getCookie, setCookie } from "../wisDataLayer"
import "../assets/style/components/_cookie-banner.scss"
export default class CookieBanner extends Component {
  constructor(props) {
    super(props)

    this.state = {
      showConsent: false,
      loaded: false,
    }
  }

  componentDidMount() {
    this.checkSite()
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevProps !== this.props) {
      this.checkSite()
    }
  }
  checkSite() {
    if (getCookie("userConsent") === "") {
      if (
        window.location.pathname.includes("datenschutz") ||
        window.location.pathname.includes("impressum")
      ) {
        this.setState({ showConsent: false })
      } else {
        document.body.style.height = "100vh"
        document.body.style.overflowY = "hidden"
        this.setState({ showConsent: true })
      }
    } else{
      if(getCookie("userConsent") === 'true'){
        this.loadScript()
      }
    }
  }
  loadScript() {
    if(!this.state.loaded){
      // var s = document.createElement("script")
      // var f = document.getElementsByTagName("script")[0]
      // s.src = "https://www.googletagmanager.com/gtag/js?id=UA-209694541-1"
      // s.type = "text/javascript"
      // f.parentNode.insertBefore(s, f)
      // this.setState({loaded: true})
    }
  }

  updateConsent(allow) {
    const expDate = new Date(new Date().getTime() + 1000 * 60 * 60 * 24 * 365)
    setCookie("userConsent", allow, expDate)
    this.setState({ showConsent: false }, () => {
      document.body.style.height = ""
      document.body.style.overflowY = "unset"
    })
    if(allow === true){
      this.loadScript()
    }
  }
  render() {
    return (
      <>
        {this.state.showConsent && (
          <div className="cookie-banner">
            <div className="cookie-banner-container">
              <h2>Diese Webseite verwendet Cookies</h2>
              {/* <p>Wir verwenden Cookies, um Inhalte und Anzeigen zu personalisieren, Funktionen für soziale Medien anbieten zu können und die Zugriffe auf unsere Website zu analysieren. Außerdem geben wir Informationen zu Ihrer Verwendung unserer Website an unsere Partner für soziale Medien, Werbung und Analysen weiter. Unsere Partner führen diese Informationen möglicherweise mit weiteren Daten zusammen, die Sie ihnen bereitgestellt haben oder die sie im Rahmen Ihrer Nutzung der Dienste gesammelt haben. Weitere Informationen finden Sie in unserem <a title="Impressum" href="/impressum/"><span> Impressum</span></a> oder unter unseren <a title="Datenschutzbestimmungen" href="/datenschutz/"><span> Datenschutzbestimmungen</span></a>. Dort können Sie Ihre Zustimmung jederzeit widerrufen.</p> */}
              <p>
                Wir verwenden Cookies, um die Zugriffe auf unsere Website zu
                analysieren. Außerdem geben wir Informationen zu Ihrer
                Verwendung unserer Website an unsere Partner weiter. Unsere
                Partner führen diese Informationen möglicherweise mit weiteren
                Daten zusammen, die Sie ihnen bereitgestellt haben oder die sie
                im Rahmen Ihrer Nutzung der Dienste gesammelt haben. Weitere
                Informationen finden Sie in unserem{" "}
                <a title="Impressum" href="/impressum/">
                  <span> Impressum</span>
                </a>{" "}
                oder unter unseren{" "}
                <a title="Datenschutzbestimmungen" href="/datenschutz/">
                  <span> Datenschutzbestimmungen</span>
                </a>
                . Dort können Sie Ihre Zustimmung jederzeit widerrufen.
              </p>
              <div className="cookie-banner-container-controls">
                <button
                  className="wis-btn wis-btn-primary"
                  onClick={() => this.updateConsent(true)}
                >
                  ALLE AKZEPTIEREN
                </button>
                <button
                  className="wis-btn wis-btn-secondary"
                  onClick={() => this.updateConsent(false)}
                >
                  ABLEHNEN
                </button>
              </div>
            </div>
          </div>
        )}
        {this.props.children}
      </>
    )
  }
}
