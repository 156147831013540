import aerobicUStep from "../assets/videos/aerobic_u_step.mp4";
import ballett from "../assets/videos/ballett.mp4";
import basketball1 from "../assets/videos/basketball_1.mp4";
import dance from "../assets/videos/dance.mp4";
import fechten from "../assets/videos/fechten.mp4";
import jazzNmore from "../assets/videos/jazzNmore.mp4";
import kinder from "../assets/videos/kinder.mp4";
import kunstturner from "../assets/videos/kunstturner.mp4";
import kunstturnerinnen from "../assets/videos/kunstturnerinnen.mp4";
import leichtathletik from "../assets/videos/leichtathletik.mp4";
import rsg from "../assets/videos/rsg.mp4";
import senioren from "../assets/videos/senioren.mp4";
import seniorinnen from "../assets/videos/seniorinnen.mp4";
import thaiChi from "../assets/videos/thai chi.mp4";
import tischtennis from "../assets/videos/tischtennis.mp4";
import volleyball from "../assets/videos/volleyball.mp4";

String.prototype.capitalize = function(){
    return this.charAt(0).toUpperCase() + this.slice(1);
}

export const getVideo = (videoFile) => {
    switch (videoFile) {
      case "aerobic_u_step.mp4":
        return aerobicUStep;
      case "ballett.mp4":
        return ballett;
      case "basketball_1.mp4":
        return basketball1;
      case "dance.mp4":
        return dance;
      case "fechten.mp4":
        return fechten;
      case "jazzNmore.mp4":
        return jazzNmore;
      case "kinder.mp4":
        return kinder;
      case "kunstturner.mp4":
        return kunstturner;
      case "kunstturnerinnen.mp4":
        return kunstturnerinnen;
      case "leichtathletik.mp4":
        return leichtathletik;
      case "rsg.mp4":
        return rsg;
      case "senioren.mp4":
        return senioren;
      case "seniorinnen.mp4":
        return seniorinnen;
      case "thai chi.mp4":
        return thaiChi;
      case "tischtennis.mp4":
        return tischtennis;
      case "volleyball.mp4":
        return volleyball;
      default:
        return null;
    }
  }