const saveFilterSettings = settings => {
  window.sessionStorage.setItem("filter-settings", JSON.stringify(settings))
  window.wisDataLayer.filterSettings = settings
}
const getFilterSettings = () => {
  const settings = window.sessionStorage.getItem("filter-settings")
  return JSON.parse(settings)
}
export function setCookie(name, value, livetime) {
  if (livetime !== undefined) {
    const day = new Date(livetime)
    var nextDay = new Date(day)
    nextDay.setDate(day.getDate() + 1)
    let expires = "expires=" + nextDay.toUTCString()
    document.cookie = name + "=" + value + ";" + expires + ";path=/"
  } else {
    const d = new Date()
    d.setTime(d.getTime() + 365 * 24 * 60 * 60 * 1000)
    let expires = "expires=" + d.toUTCString()
    document.cookie = name + "=" + value + ";" + expires + ";path=/"
  }
}
export function getCookie(cname) {
  var name = cname + "="
  var decodedCookie = decodeURIComponent(document.cookie)
  var ca = decodedCookie.split(";")
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i]
    while (c.charAt(0) == " ") {
      c = c.substring(1)
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length)
    }
  }
  return ""
}
export const wisDataLayer = {
  saveFilterSettings,
  getFilterSettings,
  getCookie,
  setCookie,
}
