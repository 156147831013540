import React from 'react'
import "jquery/dist/jquery.min.js"
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap/dist/js/bootstrap.min.js"
import './src/assets/style/main.scss'
import { wisDataLayer } from "./src/wisDataLayer"
import CookieBanner from "./src/components/CookieBanner"
import './src/utils'
const saveWisDataLayer = () => {
  if (typeof window !== "undefined") {
    window.wisDataLayer = wisDataLayer
  }
}
export const wrapPageElement = ({ element, props }) => {
  return <CookieBanner {...props}>{element}</CookieBanner>
}
try {
  saveWisDataLayer()
} catch (e) {
  console.log(e)
}
